<template>
	<pui-datatable
		:customizeActionAvailability="customizeActionAvailability"
		:modelName="modelName"
		:parentModelName="parentModelName"
		:parentModel="parentModel"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:readOnly="readOnly"
		:showMasterDetailFilterListBtn="true"
		:showMasterDetailFilterBtn="true"
		:showMasterDetailSortBtn="true"
		:showMasterDetailConfBtn="true"
	></pui-datatable>
</template>

<script>

import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import { STATUS_DRAFT_ID } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'imofalform2-grid',

	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'imofalform2',
			modelColumnDefs: {}
		};
	},
	methods: {
		customizeActionAvailability(actionId, parentModel) {
			if (actionId === 'puiImportAction' || actionId === 'puiExportAction') {
				return parentModel.statusid === STATUS_DRAFT_ID;
			}
		}
	}
};
</script>
